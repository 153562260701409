import { z } from 'zod';
import { BRANDS } from '@cof/plastic-components';

export const getSearchParameter = (parameter) => {
    const query = Object.fromEntries(new URLSearchParams(window.location.search));
    const lowerCaseParams = new URLSearchParams();
    Object.entries(query).forEach(([key, value]) => lowerCaseParams.append(key.toLowerCase(), value));
    return lowerCaseParams.get(parameter) || undefined; // undefined is added because query.get returns null if not found
};

export const getChannel = () => {
    const rawChannel = getSearchParameter('channel');

    return /^(email|sms|qcp|per)[0-9a-z]{0,5}$/i.test(rawChannel) ? rawChannel.toUpperCase() : 'NONE';
};

export const getVendorCodeFromUrl = () => {
    const validVendorCode = (vendorCode) => (/^[0-9A-Za-z]{3,}$/.test(vendorCode) ? vendorCode : undefined);

    const s = getSearchParameter('s');
    const sVendorCode = s?.substring(8, 11)?.replace('x', '');

    return validVendorCode(getSearchParameter('v')) || validVendorCode(sVendorCode);
};

export const padDate = (number) => number.toString().padStart(2, '0');

export const getFormattedDate = (numberObj) =>
    `${numberObj.year}-${padDate(numberObj.month)}-${padDate(numberObj.day)}`;

export const formSchema = z.object({
    dateOfBirth: z.object({
        day: z
            .string()
            .min(1)
            .transform((val) => Number(val))
            .refine((val) => val > 0 && val <= 31),
        month: z
            .string()
            .min(1)
            .transform((val) => Number(val))
            .refine((val) => val > 0 && val <= 12),
        year: z
            .string()
            .min(1)
            .transform((val) => Number(val))
            .refine((val) => val >= 1900 && val < new Date().getFullYear())
    })
});

export const AGE_RANGE_ERROR = 'age_range_error';
export const formSchemaWithRetryVariant = z.object({
    dateOfBirth: z
        .object({
            day: z
                .string()
                .min(1)
                .transform((val) => Number(val))
                .refine((val) => val > 0 && val <= 31),
            month: z
                .string()
                .min(1)
                .transform((val) => Number(val))
                .refine((val) => val > 0 && val <= 12),
            year: z
                .string()
                .min(1)
                .transform((val) => Number(val))
                .refine((val) => val >= 1900 && val < new Date().getFullYear())
        })
        .refine(
            (data) => {
                const { day, month, year } = data;
                if ([day, month, year].includes('')) {
                    return true;
                }
                const birthDate = new Date(year, month - 1, day);
                const today = new Date();
                let age = today.getFullYear() - birthDate.getFullYear();
                const monthDiff = today.getMonth() - birthDate.getMonth();
                const dayDiff = today.getDate() - birthDate.getDate();

                if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
                    age -= 1;
                }

                return age >= 18 && age <= 95;
            },
            {
                message: AGE_RANGE_ERROR
            }
        )
        .refine(
            (data) => {
                const { day, month, year } = data;
                const lastDayOfMonth = new Date(year, month, 0).getDate();
                return day > 0 && day <= lastDayOfMonth;
            },
            {
                path: ['day']
            }
        )
});

const PIXEL_LOAD_TIMEOUT = 500;

export const waitForPixels = () => {
    // this won't make the promise fail, but it will stop them from blocking the browser from redirecting
    const timeoutPromise = new Promise((resolve) => {
        setTimeout(resolve, PIXEL_LOAD_TIMEOUT);
    });

    const pixelsLoadedPromise = new Promise((resolve) => {
        // get all imgs with classname pixel
        const pixels = document.querySelectorAll('img.pixel');
        const promises = Array.from(pixels).map(
            (pixel) =>
                new Promise((pixelResolve) => {
                    if (pixel.complete) {
                        pixelResolve();
                    } else {
                        pixel.addEventListener('load', pixelResolve);
                        pixel.addEventListener('error', pixelResolve);
                    }
                })
        );

        Promise.allSettled(promises).then(resolve);
    });

    return Promise.race([timeoutPromise, pixelsLoadedPromise]);
};

export const shouldDisplayVerifyWithRetries = (brand) =>
    brand === BRANDS.OCEAN || brand === BRANDS.THINK_MONEY.toLowerCase();
