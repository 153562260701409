export const PREFERENCE = {
    ONLINE_STATEMENTS: 'online-statements',
    MARKETING_CONTACT_PERMISSION: 'marketing-contact-permission'
};

export const ALLOWED_BRANDS = [
    'capitalOne',
    'littlewoods',
    'luma',
    'ocean',
    'postoffice',
    'very',
    'asos',
    'thinkmoney'
];

export const CHARACTERS = {
    tab: 9,
    enter: 13,
    escape: 27,
    spacebar: 32,
    end: 35,
    home: 36,
    left: 37,
    up: 38,
    right: 39,
    down: 40
};

export const ERROR_TYPE = {
    SERVER_ERROR: 'ServerError'
};

export const REASON = {
    INCORRECT: 'INCORRECT',
    EXPIRED: 'EXPIRED',
    TIMEOUT: 'TIMEOUT'
};

export const HOSTED_PARTNERS = ['luma', 'littlewoods', 'very', 'asos'];
