import React from 'react';
import { styled, css } from 'styled-components';

import { Flex, Header as PlasticHeader, useBrand } from '@cof/plastic-components';
import { PropTypes } from 'prop-types';
import Logo from '../Logo';

import { shouldDisplayVerifyWithRetries } from '../../utils';

const FeefoAward = styled.img`
    ${({ theme: { breakpoints } }) => css`
        width: 140px;
        ${breakpoints.mq.tabletMin} {
            width: 207px;
        }
    `}
`;

export const LogoComponent = () => (
    <Flex width="100%" height="100%" justifyContent={['center', null, 'flex-start']} alignItems="center">
        <Logo />
    </Flex>
);

export const VerifyWithRetriesLogoComponent = ({ isFeefoPlatinumAward }) => (
    <Flex width="100%" height="100%" justifyContent={['space-around', null, 'space-between']}>
        <Logo width="170px" />
        {isFeefoPlatinumAward && (
            <FeefoAward
                src={`${process.env.PUBLIC_URL}/assets/svg/Feefo-Platinum-Logo.svg`}
                alt="Feefo 2024 Platinum Trusted Service Award Winner"
            />
        )}
    </Flex>
);

VerifyWithRetriesLogoComponent.propTypes = {
    isFeefoPlatinumAward: PropTypes.bool.isRequired
};

const StyledPlasticHeader = styled(PlasticHeader)`
    ${({ theme }) => theme.components.verify.appHeader};
    padding: 0;
`;

const Header = () => {
    const brand = useBrand();
    const isVerifyWithRetries = shouldDisplayVerifyWithRetries(brand);
    return (
        <StyledPlasticHeader bordered={false}>
            {isVerifyWithRetries ? (
                <VerifyWithRetriesLogoComponent isFeefoPlatinumAward={isVerifyWithRetries} />
            ) : (
                <LogoComponent />
            )}
        </StyledPlasticHeader>
    );
};

Header.propTypes = {};

export default Header;
